@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


@font-face {
  font-family: "england";   /*Can be any text*/
  src: local("england"),
    url("/src/fonts/england.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: 'Inter', 'Open Sans', 'sans-serif';
}

* {
  box-sizing: border-box;
}
